import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Shared from "./Challenges/Shared";
import HomePage from "./HomePage";

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/challenges/:type" component={Shared} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default App;

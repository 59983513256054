import { Link } from "react-router-dom";

import styles from "./HomePage.module.css";

const HomePage = () => (
  <div className={styles.main}>
    <h1>Welcome to OpenLoop's Coding Challenges</h1>
    <p>Available Challenges:</p>
    <nav>
      <ul>
        <li>
          <Link to="/challenges/frontend">Frontend</Link>
        </li>
        <li>
          <Link to="/challenges/fullstack">Fullstack</Link>
        </li>
        <li>
          <Link to="/challenges/backend">Backend</Link>
        </li>
      </ul>
    </nav>
  </div>
);

export default HomePage;

const BackendInstructions = () => (
  <>
    <p>
      Create a REST or Graph API backed by a database that does the following:
    </p>
    <ul>
      <li>creates a user</li>
      <li>fetches a list of users</li>
      <li>updates a user</li>
      <li>deletes a user</li>
    </ul>
    <p>Please use the following user schema:</p>
    <pre>
      &#123; id: string, firstName: string, lastName: string, note: string
      &#125;
    </pre>
    
    <h3>Technical Requirements</h3>
    <ul>
      <li>
        Your submission is written in Node, Python, or Java
      </li>
      <li>
        Your submission follows API design best practices (error handling, for example)
      </li>
      <li>
        Your submission runs out of the box and contains clear setup instructions - we would prefer you use a containerization tool like docker-compose for ease of use
      </li>
    </ul>
  
    <h3>Bonus Points</h3>
    <p>
      Our backend stack is Node, Express, TypeScript, Apollo Server, MongoDB,
      and Docker. If you feel confident with any of those technologies,
      including them would be a great way to demonstrate that you're ready to
      hop in and contribute! Also, if you find the time to write a few unit or
      integration tests, we would definitely be impressed :)
    </p>

    <h3>Guidelines</h3>
    <p>
      Please do not spend more than 2.5 hours on this! We know you're busy and
      have already invested a lot of time in our interview process. Also, if you
      could include a `README` that tells us how to run the thing, that would be
      great. &lt;3
    </p>
  </>
);

export default BackendInstructions;

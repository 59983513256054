import styles from "./Instructions.module.css";
import FrontendInstructions from "./FrontendInstructions";
import FullstackInstructions from "./FullstackInstructions";
import BackendInstructions from "./BackendInstructions";

interface Props {
  challengeType?: "frontend" | "fullstack" | "backend";
}

const Instructions = ({ challengeType }: Props) => {
  const isFullStackChallenge = challengeType === "fullstack";
  const isFrontendChallenge = challengeType === "frontend";
  const isBackendChallenge = challengeType === "backend";
  return (
    <div className={styles.main}>
      <h1>Welcome 👋</h1>
      <h2>The Challenge</h2>
      {isFrontendChallenge && <FrontendInstructions />}
      {isFullStackChallenge && <FullstackInstructions />}
      {isBackendChallenge && <BackendInstructions />}
      <h3>When You're Finished</h3>
      <p>
        Upload your repository to
        Github and add Mitch Cravens (thetoxicavenger) as a collaborator (make sure that it's private, please!). If you
        could also send us an email letting us know that you're done, that would
        be great. We'll take a look at your submission and be in touch soon
        regarding next steps!
      </p>
    </div>
  );
};

export default Instructions;

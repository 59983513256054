const FrontendInstructions = () => (
  <>
    <p>
      Build a React app that presents a user with a form, performs some simple
      validation, adds submissions to a list, and allows the user to remove
      submissions from said list.
    </p>
    <h3>First, a few notes</h3>
    <ul>
      <li>Don't spend too much time on this - we know you're busy!</li>
      <li>
        Be prepared to discuss details about your implementation. You'll have a
        chance to point out things you could have done differently, or things
        you were unsure of. Then we'll pair-program to add some features to it.
      </li>
      <li>If you have questions, don't hesitate to ask us.</li>
      <li>
        Don't worry <i>too much</i> about the styling, as{" "}
        <i>we care more about how your submission functions</i>.
      </li>
    </ul>
    <h3>Requirements</h3>
    <ul>
      <li>
        Built with React. Feel free to use{" "}
        <a
          href="https://create-react-app.dev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Create React App
        </a>{" "}
        if you'd prefer, along with any other libraries (UI, forms, validation,
        etc.) that help you get the job done.
      </li>
      <li>Form on the left, list of submissions on the right.</li>
      <li>
        The first field in the form should auto-focus on page load and after
        each submission.
      </li>
      <li>Form contains fields for: first name, last name, email, and note.</li>
      <li>
        Each item in the submissions list should display: first name, last name,
        note, and email.
      </li>
      <li>
        All form fields are required.
        <ul>
          <li>
            If a field has a validation error, display a status message below
            it, and indicate the error with a red border.
          </li>
          <li>Ensure the `email` field value is the correct format.</li>
        </ul>
      </li>
      <li>Disable form submissions unless the form is valid.</li>
      <li>Reset the form on submit.</li>
      <li>Allow the user to remove submissions from the list.</li>
    </ul>

    <h3>Bonus Points</h3>
    <ul>
      <li>
        Write unit test(s) for one or more components using react testing
        library.
      </li>
      <li>Write an e2e test for adding a user to the list.</li>
    </ul>
  </>
);

export default FrontendInstructions;

import { useCallback, useEffect, useRef } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import InputStatus from '../InputStatus';
import { User } from '../../../types';

import styles from './AddUserForm.module.css';

interface FormValues extends User {};

interface Props {
  onSubmit: (user: User) => void;
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Must be a valid email').required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  note: yup.string().required('Note is required'),
})

const AddUserForm = ({ onSubmit }: Props) => {
  const firstInputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }

  const handleSubmit = useCallback<(values: FormValues, helpers: FormikHelpers<FormValues>) => void>((values, { resetForm }) => {
    onSubmit(values);
    focusInput();
    resetForm();
  }, [onSubmit]);

  useEffect(() => {
    focusInput();
  }, []);

  return (
    <Formik<FormValues>
      initialValues={{ email: '', firstName: '', lastName: '', note: '' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, handleBlur, handleChange, isValid, touched, values }) => (
        <Form>
          <div className={styles.formItem}>
            <label htmlFor="firstName">First Name</label>
            <input
              aria-invalid={touched.firstName && !!errors.firstName}
              id="firstName"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              ref={firstInputRef}
              value={values.firstName}
            />
            <InputStatus name="firstName" />
          </div>
          <div className={styles.formItem}>
            <label htmlFor="lastName">Last Name</label>
            <input
              aria-invalid={touched.lastName && !!errors.lastName}
              id="lastName"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
            />
            <InputStatus name="lastName" />
          </div>
          <div className={styles.formItem}>
            <label htmlFor="email">Email</label>
            <input
              aria-invalid={touched.email && !!errors.email}
              id="email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
            />
            <InputStatus name="email" />
          </div>
          <div className={styles.formItem}>
            <label htmlFor="note">Note</label>
            <input
              aria-invalid={touched.note && !!errors.note}
              id="note"
              name="note"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.note}
            />
            <InputStatus name="note" />
          </div>
          <div className={styles.formItem}>
            <button disabled={!dirty || !isValid} type="submit">+ Add User</button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddUserForm;

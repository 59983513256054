import { User } from '../../../types';

interface Props {
  removeUser: (idx: number) => void;
  users: User[];
}

const UserList = ({ removeUser, users }: Props) => (
  <ul>
    {users.length === 0 && (
      <li>No users yet...</li>
    )}
    {users.map(({ email, firstName, lastName, note }, idx) => (
      <li key={email}>
        {firstName} {lastName} | {note} | {email} <button onClick={() => removeUser(idx)}>Remove</button>
      </li>
    ))}
  </ul>
);

export default UserList;

import { useState } from "react";

import CodingChallenge from "./Demo";
import Instructions from "./Instructions";

import styles from "./Shared.module.css";
import { useParams } from "react-router-dom";

enum View {
  Instructions,
  Demo,
}

const Shared = () => {
  const [view, setView] = useState<View>(View.Demo);
  const { type: challengeType } =
    useParams<{ type: "frontend" | "fullstack" | "backend" }>();

  // escape hatch for the fact that there's no backend demo
  // TODO: this is lazy
  if (challengeType === "backend") {
    return <Instructions challengeType={challengeType} />;
  }
  return (
    <>
      <button
        className={styles.toggleButton}
        onClick={() =>
          setView(view === View.Demo ? View.Instructions : View.Demo)
        }
      >
        View {view === View.Demo ? "Instructions" : "Demo"}
      </button>
      {view === View.Demo ? (
        <CodingChallenge />
      ) : (
        <Instructions challengeType={challengeType} />
      )}
    </>
  );
};

export default Shared;

const FullstackInstructions = () => (
  <>
    <p>
      Build a fullstack React application that does the following:
      <ul>
        <li>Loads a list of users.</li>
        <li>Allows you to add a user.</li>
        <li>Allows you to delete a user.</li>
      </ul>
    </p>

    <h2>First, a few notes</h2>
    <ul>
      <li>Don't spend too much time on this - we know you're busy!</li>
      <li>
        Be prepared to discuss details about your implementation. You'll have a
        chance to point out things you could have done differently, or things
        you were unsure of. Then we'll pair-program to add some features to it.
      </li>
      <li>If you have questions, don't hesitate to ask us.</li>
      <li>
        Don't worry <i>too much</i> about the styling, as{" "}
        <i>we care more about how your submission functions</i>.
      </li>
      <li>Please include a README to tell us how to run the thing!</li>
    </ul>

    <h2>Technical Requirements</h2>
    <ul>
      <li>
        Your frontend code is written in React
      </li>
      <li>
        Your backend code is written in Node, Python, or Java
      </li>
      <li>
        Your backend submission follows API design best practices (error handling, for example)
      </li>
      <li>
        Your submission runs out of the box and contains clear setup instructions. Using docker compose and providing a README would be one way to accomplish this.
      </li>
    </ul>

    <h2>Frontend Specifications</h2>

    <h3>Form Fields</h3>
    <ul>
      <li>First name</li>
      <li>Last name</li>
      <li>Email</li>
      <li>Note</li>
    </ul>

    <h3>On Page Load</h3>
    <ul>
      <li>Fetch and render the list of users.</li>
      <li>The first field in the form should auto-focus.</li>
    </ul>

    <h3>Form Validation</h3>
    <ul>
      <li>All form fields are required.</li>
      <li>
        If a field has a validation error, display a status message below it,
        and indicate the error with a red border.
      </li>
      <li>Ensure the `email` field value is the correct format.</li>
    </ul>

    <h3>On Submit</h3>
    <ul>
      <li>Form should only submit if all fields are valid.</li>
      <li>Focus should jump back to the first field in the form.</li>
      <li>Reset the form.</li>
    </ul>

    <h3>Users List</h3>
    <ul>
      <li>
        Each item in the users list should display first name, last name, note,
        and email.
      </li>
      <li>
        Users are deletable on click. Deletions should be persisted to the
        database.
      </li>
    </ul>

    <h3>Bonus Points</h3>
    <p>
      Our backend stack is Node, Express, TypeScript, Apollo Server, MongoDB,
      and Docker. On the frontend, we also use Formik and Apollo Client. If you feel confident with any of those technologies,
      including them would be a great way to demonstrate that you're ready to
      hop in and contribute! Also, if you find the time to write a few unit or integration tests, we would
      definitely be impressed :)
    </p>
  </>
);

export default FullstackInstructions;

import { useCallback, useState } from 'react';

import AddUserForm from '../AddUserForm';
import { User } from '../../../types';
import UserList from '../UserList';

import styles from './Demo.module.css';

const Demo = () => {
  const [users, setUsers] = useState<User[]>([]);

  const onSubmit = useCallback((user: User) => {
    setUsers([user, ...users])
  }, [users]);

  const removeUser = useCallback<(idx: number) => void>(idx => {
    const newUsers = [...users];
    newUsers.splice(idx, 1);
    setUsers(newUsers);
  }, [users]);

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.titleRow}>
          <h1>Add Users</h1>
        </div>
        <div className={styles.col}>
          <AddUserForm onSubmit={onSubmit} />
        </div>
        <div className={styles.col}>
          <UserList removeUser={removeUser} users={users} />
        </div>
      </div>
    </div>
  );
};

export default Demo;
